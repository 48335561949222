import { gql } from '@apollo/client';

export const OFFICE_HAS_REFERENCES_QUERY = gql`
  query OfficeHasReferences($input: OfficeInput) {
    getOffice(input: $input) {
      id
      hasReferences
    }
  }
`;
