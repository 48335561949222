import { gql } from '@apollo/client';

export const OFFICE_ABOUT_REFERENCES_QUERY = gql`
  query OfficeAbout($input: OfficeInput) {
    office: getOffice(input: $input) {
      id
      name
      county
      municipality
      place
      zip
      about
      hasReferences
      referencesText
      referencesFormText
      seo {
        title
        description
        image
      }
    }
  }
`;
