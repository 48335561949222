import { useMutation } from '@apollo/client';
import * as React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { createLeadMutation } from '../../../../../client/__graphql__/mutations';

import { Paragraph } from '../../../ui/text/Paragraph';
import { Form, FormRow } from '../../../ui/form/Form';
import { ButtonGroup } from '../../../ui/button/ButtonGroup';
import { ButtonLink } from '../../../ui/link/ButtonLink';
import { Button } from '../../../ui/button/Button';
import { FormError } from '../../../ui/form/FormError';
import { Input } from '../../../ui/form/Input';
import { Textarea } from '../../../ui/form/Textarea';

import { SiteContext } from '../../../../utils/context/SiteContext';
import { useFormTransition } from '../../../../utils/hooks/useFormTransition';
import { useForm } from '../../../../utils/hooks/useForm';

import { ILeadInput, ILeadResponse } from '../../../../__types__/global';
import { Heading } from '../../../ui/text/Heading';
import { Container } from '../../../layout/Container';
import { Section } from '../../../layout/Section';
import { useRouteMatch } from 'react-router';

const MAIL_MUTATION = createLeadMutation({
  mail: {
    response: `{
        success
      }`
  }
});

export const FormSection: React.FC<{ text?: string }> = React.memo(
  ({ text }) => {
    const match = useRouteMatch<{ urltag: string }>();
    const { routeConfig } = React.useContext(SiteContext);

    const [posted, setPosted] = React.useState<boolean>(false);

    const { fadeIn, fadeOut } = useFormTransition({
      posted
    });

    const [mail, { loading, error }] = useMutation<ILeadResponse, ILeadInput>(
      MAIL_MUTATION,
      {
        onCompleted: ({ lead }) => {
          if (lead && lead.mail && lead.mail.success) {
            setPosted(true);
          }
        }
      }
    );

    const { fields, getFields, handleSubmit, handleChange } = useForm({
      fields: [
        {
          type: 'text',
          name: 'address',
          placeholder: 'Eks. Adresseveien 23',
          value: '',
          label: 'Adresse *',
          required: true
        },
        {
          type: 'text',
          name: 'name',
          placeholder: 'Eks. Ola Nordmann',
          value: '',
          label: 'Navn *',
          required: true
        },
        {
          type: 'email',
          name: 'email',
          placeholder: 'Eks. ole@mail.no',
          value: '',
          label: 'E-post *',
          labelAfter: '(Brukes til å kontakte deg)',
          required: true
        },
        {
          type: 'textarea',
          name: 'comment',
          placeholder: 'Eks. Skriv tilbakemelding her',
          value: '',
          label: 'Kort tilbakemelding *',
          required: true
        }
      ],
      submit: () => {
        const preparedFields = getFields(fields);
        mail({
          variables: {
            input: {
              ...preparedFields,
              referer: window.location.href,
              leadType: 'EPOST',
              mailType: 12,
              identifier: match?.params.urltag,
              zip: '0000'
            }
          }
        });
      }
    });

    return (
      <Container>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            {text ? (
              <FormText dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              <>
                <Heading tag="h1" center={true}>
                  Ønsker du å dele din opplevelse med oss?
                </Heading>
                <Paragraph center={true} className="form-text">
                  Vi lever av fornøyde kunder. Faktisk kommer 7 av 10 av våre
                  nye kunder på anbefaling fra tidligere kunder. Ønsker du å
                  dele din opplevelse med oss, setter vi stor pris på det.
                </Paragraph>
              </>
            )}
          </Section>
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields
              .filter((item: any) => item.type !== 'textarea')
              .map((item: any, index: number) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item.type}
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            {fields
              .filter((item: any) => item.type === 'textarea')
              .map((item: any, index: number) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Textarea
                      name={item.name}
                      label={item.label}
                      labelAfter={item.labelAfter}
                      placeholder={item.placeholder}
                      value={item.value}
                      onChange={handleChange}
                      error={item.error}
                    />
                  </FormRow>
                );
              })}
            <Paragraph>
              Vi setter pris på din tilbakemelding. Ved å trykke «send» godtar
              du at tilbakemelding og ditt navn kan benyttes i vår
              kommunikasjon. <br />
              På forhånd takk!
            </Paragraph>
            <ButtonGroup>
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Send
              </Button>
              <ButtonLink
                as="a"
                href={routeConfig?.personvern?.url}
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <FormWrapper style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk for din tilbakemelding
            </Heading>
          </Section>
        </FormWrapper>
      </Container>
    );
  }
);

const FormWrapper = styled(animated.div)``;
const FormText = styled.div`
  text-align: center;
`;
