import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { animated } from 'react-spring';

import { useQuery } from '@apollo/client';

import { Seo } from '../../../../utils/seo/Index';
import { FormSection } from './FormSection';
import { useFadeTransition } from '../../../../utils/hooks/useFadeTransition';
import { Container } from '../../../layout/Container';
import NoMatch from '../../404Old';
import { OFFICE_HAS_REFERENCES_QUERY } from '../../../../../client/__graphql__/queries/office/references/hasReferences';
import { OFFICE_ABOUT_REFERENCES_QUERY } from '../../../../../client/__graphql__/queries/office/references/about';

const Feedback: React.FC = () => {
  const params = useParams<{ urltag: string }>();
  const location = useLocation();

  const { fadeIn } = useFadeTransition({
    immidiate: true
  });

  const {
    data: office,
    loading,
    error
  } = useQuery(OFFICE_HAS_REFERENCES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        urltag: params?.urltag
      }
    }
  });

  const { data } = useQuery(OFFICE_ABOUT_REFERENCES_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        urltag: params?.urltag
      }
    },
    onError: ({ graphQLErrors }) => {
      graphQLErrors?.map((item: any, index: number) => {});
    }
  });

  if (office?.getOffice?.hasReferences === false) {
    return <NoMatch />;
  }

  if (error?.graphQLErrors?.length) {
    if (error?.graphQLErrors[0]?.extensions?.code === '404') {
      return <NoMatch />;
    }
  }

  return (
    <animated.div style={fadeIn}>
      <Seo
        title={`PrivatMegleren - Eiendomsmegler ${data?.office?.name} - Referanser`}
        description="Se referanser fra fornøyde kunder"
        image={
          data?.office?.seo?.image
            ? data.office.seo.image
            : 'https://cdn.reeltime.no/pm_assets/img/bakgrunner/pmfront.jpg'
        }
        url={location.pathname}
        imageTitle="/PrivatMegleren-eiendomsmegler-kjøp-salg-eiendom-leilighet-hus-bolig"
      />
      <Container style={{ paddingTop: '100px' }}>
        <FormSection text={data?.office?.referencesFormText} />
      </Container>
    </animated.div>
  );
};

export default Feedback;
